main{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    
}

.mainimage_container{
    font-size: 21px;
    display: flex;
    /* flex-wrap: wrap; */
   padding: 10%;
   padding-bottom: 5%;
   justify-content: center;
   align-items: center;
   
 /* Adjusted to account for the height of the header, e.g., 50px */
  overflow: hidden; /* Set a specific height, e.g., viewport height */
    width: 100%;
    height:auto;
    background-color:whitesmoke;
}
.maintext{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}
.mainimg{
    display:flex;
    position: relative;
    justify-content:end;
    
}
.mainimage_container .mainimg img{

    width: 80%;
      height: 80%;
      object-fit: contain;
      display: block;
}
.mainimage_container h1{

        margin-bottom: 10px;
        color:#187477;
        font-size:60px;
        text-align: center;
        
}
.mainimage_container p{
    font-size: 18px;
    text-align: center;
}
.mainimage_container button{
    background-color: #c3996b;
      color: white;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
    margin-top: 10px;
}
.mainimage_container button:hover{
    background-color: #c3996b58;
    color: #c3996b;
}
.aboutus{

    display:flex;
    justify-content:center;
    align-items:flex-start;
    flex-wrap: wrap;
    width: 100%;
   height:100vh;
    /* padding: 20px; */
    position:relative;
    background:#c3996b18;
    /* background-color: #187477; */
    
}

/* .aboutus img.background-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
} */

/* .aboutus::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: linear-gradient(90deg, rgba(53, 179, 184, 0.6) 0%, rgba(24, 116, 119, 0.6) 100%);
    
    z-index: 1;/* Semi-transparent blue layer */
/* } */ 



/* .aboutus h2 {
    color: #c3996b;
    opacity: 1;
    text-align: center;
    position: relative; 
} */
.aboutuscontainer{
    text-align: center;
    font-weight: 500;
    width: 50%;
    height:100%;
    color: white;
    line-height: 2;
    position: relative; 
display: flex;
justify-content: center;
align-items: start;
flex-wrap: wrap;
flex-direction: column;
padding: 20px;

}
.aboutuscontainer button{
    background-color: #c3996b;
      color: white;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
    margin-top: 10px;
}
.aboutuscontainer button:hover{
    background-color: #c3996b58;
    color: #c3996b;
}
/* .aboutuspara {
    text-align: center;
    font-weight: 500;
    width: 50%;
    color: white;
    line-height: 2;
    position: relative; 
    z-index: 2; 
} */

.aboutuscontainer:first-child{
    justify-content: start;
}
.aboutuscontainer h1{
    font-size: 54px;
    margin-bottom:10px;
    color: white;
   position:relative;
    opacity: 1;
    text-align: left;
    
    /* -webkit-text-stroke: 1px #ff8400; */
}
.aboutuscontainer h1::after{
    content: '';
    position: absolute;
    bottom: -1px; /* Adjust as needed */
    left: 0;
    width: 100%;
    height: 5px; /* Adjust thickness of the underline */
    background-color: #c3996b;
}
.aboutuscontainer p{
    text-align: left;
}
/* .aboutuscontainer img{
    width:350px;
    height:350px;
} */
.aboutusimage{
    width:35%;
    display: flex;
    justify-content: center;
}
.aboutusimage img{

    width: 100%;
      height: 100%;
      

}

.last-text{
display: flex;
}
.text1{
    text-align: left;
    margin:20px;
    margin-left: 0px;
    margin-top: 10px;
}

.last-text h3{
    color: #c3996b;
    font-size:60px;
    font-weight:700;
}

.last-text h5{
    
    font-size:18px;
    font-weight:500;
    line-height:18px
}




/* why us css below */
.whyuscontainer{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height:100%;
    width:100%;
    background: whitesmoke;
    padding: 20px;
}
.whyuscontainer h1{
    margin: 20px;
    font-size:60px;
    font-weight:700;
    position: relative;
}
.whyuscontainer h1::after{
    content: '';
    position: absolute;
    bottom: -10px; /* Adjust as needed */
    left: 0;
    width: 100%;
    height: 5px; /* Adjust thickness of the underline */
    background-color: #c3996b;

}
.whyuscontainer h3{
    font-size:18px;
    font-weight:500;
    line-height:18px;
    margin-top:30px;
}
.whyuscardcontainer{
    display:flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height:100%;
   
}
.whyuscard{
   
    max-height:500px;
   max-width:400px;
    background-color: #c3996b;
    border-radius: 20px;
    margin:20px;
    padding:20px 30px;
    position:relative;
    background:rgba(255,255,255,0.2) ;
    box-shadow: 10px 10px 25px rgba(0,0,0,0.2);
    transition: 0.5s;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction: column;
}

.whyuscard img{
    width: 100px;
    height:100px;
}

.whyuscard h1{
    margin: 20px;
    font-size:16px;
    font-weight:700;
    position: relative;
    color: #c3996b;
}
.whyuscard h1::after{
    content: '';
    position: absolute;
    bottom: -10px; /* Adjust as needed */
    left: 0;
    width: 100%;
    height: 5px; /* Adjust thickness of the underline */
    background-color:rgba(255,255,255,0.2) ;

}

.whyuscard p{
    padding:20px;
    text-align: center;
}
/* package-2 container css below */
.packagecontainermain{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    height:100vh;
    width:100%;
    background:#c3996b18;
    padding: 20px;
}

.packagecontainermain h1{
    text-align: center;
    width: 100%;
    color: white;
    padding: 20px;
    font-size:22px;
    font-weight: 400;
}
.packagecontainer{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    height:100vh;
    width:100%;
    background:#c3996b18;
    padding: 20px;
}



.packagecontainer h1{
    margin:0;
    font-size:24px;
    font-weight:700;
    text-align: start;
    position: relative;
    padding: 10px;
    width:auto;
}
.packagecontainer h1::after{
    content: '';
    position: absolute;
    bottom: -10px; /* Adjust as needed */
    left: 0;
    width: 100%;
    height: 5px; /* Adjust thickness of the underline */
    background-color: #c3996b;

}
.packagecontainer h3{
    font-size:18px;
    font-weight:500;
    line-height:18px;
    margin-top:30px;

}
.packagecardcontainer{
    display:flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height:100%;
   
}
.packagecard{
   
    height:350px;
    width:250px;
    background-color: #c3996b;
    border-radius: 10px;
    margin:20px;
    padding: 5px;
    position:relative;
    background:rgba(255,255,255,0.7) ;
    box-shadow: 10px 10px 25px rgba(0,0,0,0.2);
    transition: 0.5s;
    display:flex;
    flex-direction: column;
    justify-content:start;
    align-items:center;
}
.packagecardimagecontainer{
    display:flex;
    flex-direction: column;
    justify-content:start;
    align-items:start;
    position: relative;
    width:100%;
    height: 70%;
    overflow:hidden;
    border-radius:10px;
   
}

.packagecardimagecontainer img{
    width:100%;
    height:100%;
    /* object-fit: contain; */
   
    
}
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3); /* Adjust opacity by changing the last value */
  }
  .packagecardimagecontainer h3 {
    position: absolute;
    top:65%;
    left: 10px;
    
    color: white; /* Set text color */
    z-index: 1; /* Ensure h3 appears above overlay */
  }


  .packagecard .ptag{
    padding:10px;
    color: black;
   
    
  }

  .ptag h3{
    font-size:24px ;
    font-weight: 600;
    color:red;
  }
  .link-with-arrow {
    position: relative;
    display: inline-block;
    color: #187477; /* Change color as needed */
    text-decoration: none;
    font-weight: 500;
    height:10%;
  }
  
  
  




/* galarycontianer css below */
.gallerycontainer{
    position: relative;
    width:100%;
    height:100%;
    background:whitesmoke;
    display:flex;
    justify-content:space-around;
    align-items:center;
    padding:20px;
}
.gallerycontainer h3{
    font-size:18px;
    font-weight:500;
    
    margin:20px;
}
.gallerycontainer h1{
    margin: 20px;
    font-size:60px;
    font-weight:300;
    position: relative;
    color: #187477;
   
}
.gallerycontainer strong{
    color:#000000;
}
/* .gallerycontainer h1::after{
    content: '';
    position: absolute;
    bottom: -10px; 
    left: 0;
    width: 100%;
    height: 5px; 
    background-color: #c3996b;

} */
.gallerycontainer button{
    background-color: #c3996b;
      color: white;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
      margin: 20px;
      margin-top:10px;
}
.gallerycontainer button:hover{
    background-color: #c3996b58;
    color: #c3996b;
}
.galleryText{
    width:50%;
    height:100%;
   display:flex;
   flex-direction: column;
   justify-content:space-between;
   align-items:start;
   flex-wrap: wrap;
}

.galleryimage{
    width:600px;
    height:450px;
    border-radius: 20px;
    background:#187477;
    overflow: hidden;
    margin:20px;
}
.zaid-img{
    width:100%;
    height:100%;
}
/* getintouchcontainer css below */
.getintouchcontainer{
    height:100%;
    width:100%;
    background:#c3996b18;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    padding:20px;
}

.getintouchcontainer h3{
    font-size:18px;
    font-weight:500;
    line-height:18px;
    margin-top:30px;
    color:white;
}
.getintouchcontainer h1{
    margin: 20px;
    font-size:60px;
    font-weight:700;
    position: relative;
    color:white;
}
.getintouchcontainer h1::after{
    content: '';
    position: absolute;
    bottom: -10px; /* Adjust as needed */
    left: 0;
    width: 100%;
    height: 5px; /* Adjust thickness of the underline */
    background-color: #c3996b;

}




.getintouchcards{
height:350px;
width:80%;
display:flex;
justify-content:space-between;
align-items:center;
}

.getintouchcard{
    margin:20px;
    height:300px;
    width:200px;
    /* background: rgba(255, 255, 255, 0.2);
    box-shadow: 10px 10px 25px rgba(0,0,0,0.2); */
    display: flex;
    justify-content:start;
    align-items: center;
    flex-direction: column;
    color:white;
    
}

.getintouchcardimage{
    position: relative;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 10px 10px 25px rgba(0,0,0,0.2); 
    border-radius: 50%;
    height:120px;
    width:120px;
    display:flex;
    justify-content: center;
    align-items: center;
}
.getintouchcardimage:hover{
    background: rgba(255, 255, 255, 0.6);

}
.getintouchcardimage img{
    
    height:100px;
    width:100px;
    padding:10px;
}
.getintouchcard h3{
    text-align: center;
    font-size:20px;
    padding:20px;
}
.getintouchcard p{
    text-align: center;
    
}
/* testimonials css */

.testimonialscontainer{
    display:flex;
    flex-direction: column;
    justify-content:space-between;
    align-items:center;
    width: 100%;
    height:auto;
    top:50px;
    padding: 20px;
    background:whitesmoke;

}

.testimonialscontainer h3{
    font-size:18px;
    font-weight:500;
    line-height:18px;
    margin-top:30px;
}
/* .testimonialscontainer h2{
    color:#c3996b;
text-align: center;
margin-bottom: 50px;
} */
.testimonialscontainer h1{
    margin: 20px;
    font-size:60px;
    font-weight:700;
    position: relative;
   
}
.testimonialscontainer h1::after{
    content: '';
    position: absolute;
    bottom: -10px; /* Adjust as needed */
    left: 0;
    width: 100%;
    height: 5px; /* Adjust thickness of the underline */
    background-color: #c3996b;

}

.cards{
    display:flex;
    justify-content:center;
    align-items:center;
    margin:100px;
    width: 100%;
}

.card{
    border-radius: 15px;
    width: 350px;
    height:250px;
    margin:20px;
    padding:20px 30px;
    position:relative;
    background:rgba(255,255,255,0.2) ;
    box-shadow: 10px 10px 25px rgba(0,0,0,0.2);
    transition: 0.5s;
}

.card img{

    position:absolute;
    border-radius: 50%;
    height: 90px;
    width: 90px;
    top:-50px;
    left:-20px;

}

.card p{
    text-align: center;
    margin-top:50px;
}

.card:hover{
transform:scale(1.5);
background: white;
z-index: 300;

}


/* logos css */
@keyframes slide {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  
  .logos {
    overflow: hidden;
    padding: 30px 0;
    background: whitesmoke;
    white-space: nowrap;
    position: relative;
  }
  
  .logos:before,
  .logos:after {
    position: absolute;
    top: 0;
    width: 250px;
    height: 100%;
    content: "";
    z-index: 2;
  }
  
  /* .logos:before {
    left: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
  }
  
  .logos:after {
    right: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
  } */
  
  .logos:hover .logos-slide {
    animation-play-state: paused;
  }
  
  .logos-slide {
    display: inline-block;
    animation: 35s slide infinite linear;
  }
  
  .logos-slide img {
    height: 80px;
    margin: 0 40px;
  }
  .logos h1{
    text-align: center;
    margin-bottom: 12px;
  }

@media screen and (max-width: 767px) {
    .aboutuscontainer {
     flex-direction: column;
     justify-content: center;
     align-items:center; 
     /* Stack items vertically on small screens */
   }
   .aboutuscontainer img{
    display: none;
   }
   .mainimage_container{
    flex-direction: column;
    position:relative;
   
   }
   .maintext{
    padding-top:25%;
   }
   .whyuscardcontainer{
    flex-direction: column;
}
.whyuscontainer{
    height:auto;
}

.packagecardcontainer{
    flex-direction: column;
}
.packagecontainer{
    flex-direction: column!important;
    height:auto;
}
.packagecontainermain{
    height:auto;
}
.galleryimage{
    max-width:100%;
}
.gallerycontainer{
    height:auto;
    flex-direction: column;
    overflow: hidden;
}
.galleryText{
    width:auto;
    margin:0;
}
.getintouchcards{
    flex-direction: column;
    height:auto;
}
.getintouchcontainer{
    height:auto;
}
.cards{
    flex-direction: column;
    height:auto;
    
}
.card{
    margin:0px;
    margin-bottom: 80px;
   max-width:100%;
   
}
.card img{
    left:0px;
}
}

@media screen and (max-width: 1098px) {
    
   .aboutusimage {
    display: none;
   }
   .aboutuscontainer {
    width: 100%;
   }
   .aboutus{
    height:auto;
   }

   .packagecardcontainer{
    flex-direction: column;
}
.packagecontainer{
    flex-direction: column!important;
    height:auto;
}
.packagecontainermain{
    height:auto;
}
}

.slider{
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    
}
.item{
    position: absolute;
   
   
    text-align: justify;
    background-color: #fff;
   
    
    transition: 0.5s;
    left: calc(50% - 170px);
    top: 0;

    display:flex;
    flex-direction: column;
    margin:20px;
    width:300px;
   overflow:auto;
    justify-content:center;
    align-items: center;
    box-shadow: #c3996b;
    border:1px solid #c3996b;
    border-radius: 10px;
}




 .item img{
    max-width: 100%; /* Make the image responsive */
    height: auto;   /* Auto-adjust height */
    border-radius: 10px;
}
#next, #prev{
    position: absolute;
    top: 300px;
    color: #000000;
    background-color: transparent;
    border: none;
    font-size: xxx-large;
    font-family: monospace;
    font-weight: bold;
    z-index:3;
}
#next{
    
    right: 10px;
}
#prev{
    left: 10px;
}


@media screen and (max-width: 280px) {
    
    .item{
        width:200px;
        left:0;
    
    }
    #next, #prev{
        
        top: 40%;
        
    }
    
 }