/* Add this to your CSS file */
.whatsapp-sticky-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    background-color: #25d366; /* Change the background color as needed */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 1000; /* Adjust the z-index to ensure it appears above other elements */
  }

  .whatsapp-sticky-icon .whatsappimg{
    width:60px;
    height: 60px;
  }

  