*{
  padding:0;
  margin:0;
  box-sizing: border-box;
  font-family: math;
  
  /* overflow: visible; */
}
body{
  background-color:white;
}
.appdiv{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
