/* glallerypagecontainer css */
.galleryPageContainer{
    width:100%;
    height:100%;
    background-color:whitesmoke;
    position: relative;
   
}
.galleryPageContainer button{
    background-color: #c3996b;
      color: white;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
    margin-top: 10px;
}


.galleryPageContainer button:hover{
    background-color: #c3996b58;
    color: #c3996b;
}
.galleryPageMainContent{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 100px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
}
.galleryPageMainContent img{
    width:50%;  
}
.galleryPageTitleContainer{
    width:50%;
    height: 80%;
   
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items:start;
}

.galleryPageTitleContainer h1{
    color:#187477;
font-size: 80px;
text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.galleryPageTitleContainer p{
    color:red;
}

.galleryPageBanner{
    height:100px;
    background:#187477;
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
}
.galleryPageBannerContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #c3996b;
    font-weight: 600;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
.galleryPageBannerContent img{
    max-width: 60px;
    max-height: 60px;
}
/* about us type container css below */
.galleryPageDetails{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin:20%;
    margin-top:5%;
    margin-bottom:20px;
    
}

.galleryPageDetails h1{
    color:#187477;
font-size: 60px;
text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
margin-bottom: 20px;
}

.galleryPageDetails p{
color: black;
font-size: 18px;
}

.galleryPageDetails strong{
    color:red;
}
.video-container{
    display: flex;
    justify-content:center;
    align-items: center;
    height: 500px;
    margin-bottom: 20px;
}

.galleryPageGalleryContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    
}

.galleryPageGalleryContainer h1{
    color:#187477;
font-size: 60px;
text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
margin-bottom: 20px;
}

.galleryPageGalleryContainer marquee{
display: flex;

justify-content: space-between;
align-items: center;
}

.theline{
    width:100%;
    height:2px;
    background: #4c4c4c;
    opacity: 0.3;
    margin-bottom: 12px;
    display: inline-block;
    
  }
  
.imagegallery{
    height: 400px;
    width:500px;
    background:#c3996b;
    border-radius: 20px;
    display: inline-block;
    margin:10px;
    overflow:hidden;
}



@media screen and (max-width: 767px) {
    .galleryPageMainContent{
        flex-direction: column;
        margin:0;
        margin-top:100px;
       
    }
    .galleryPageMainContent img{
        width:auto;
    }
    .galleryPageMainContent{
        overflow: hidden; 
    }
    .galleryPageTitleContainer{
        width: 100%;
        padding: 20px;
        margin:0px;
    }

    .imagegallery,.video-container{
        width: 100%;
    }

    .galleryPageDetails{
        width: 100%;
        margin:0px;
        padding: 5px;
        margin-top: 10px;
    }
    }