
.commanPageContainer{
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: whitesmoke;
    position: relative;
    height: 100%;

}
.commanPageImageContainer{
   
    width: 100%;
    position: relative;
    display: inline-block;
    margin-top: 20px;
   
}

.commanPageImageContainer img{
    width: 100%;
    
          
        
}
.commanheader{
    position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Black with 50% opacity */
  display: flex;
  justify-content: end;
  align-items: start;
  text-align: left;
  flex-direction: column;
  
}
.commanheader h1 {
    position: relative;
    color: white;
    font-size: 84px;
    font-weight: bold;
    margin: 20px;
  }

  .commanheader p{
    position: relative;
    color: white;
    font-size: 17px;
    
    margin: 20px;
  }

  .commanheader  h1::after{
    content: '';
    position: absolute;
    bottom: -10px; /* Adjust as needed */
    left: 0;
    width: 100%;
    height: 5px; /* Adjust thickness of the underline */
    background-color: #c3996b;


}
.commanPageContentContainer{
    height:100%; /* temp*/
    width:100%;
    border-bottom: 2px solid #4c4c4c3b;
    display:flex;
    position: relative;
    flex-direction: column;
    justify-content:center;
    align-items: center;
}


.commanPageMainContainer{
    
    width: 50%;
    
    display: flex;
    justify-content:start;
    align-items:start;
    flex-direction: column;
    padding: 20px;
}
.commanPageMainContainer a{
  color:red;
}
.commanPageContainer u{
  color:#187477;
  margin-bottom:20px;
}

.commanPageMainContainer h1,h2,h3{
  color:#187477;
  margin-bottom: 8px;
}
.commanPageMainContainer p{
  color:#c3996b;
  margin-bottom:12px;
}
.recommended{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content:space-between;
  flex-direction: column;
  align-self:center;
  margin-bottom: 12px;
}

.rpackage{
  height:150px;
  width:150px;
  background: #187477;
  margin:20px;
  border-radius: 5px;
}

strong{
  color:#187477;
}

.theline{
  width:100%;
  height:1px;
  background: #4c4c4c;
  opacity: 0.3;
  margin-bottom: 12px;
}


.amountcontainer{
  display:flex;
  flex-direction:row;
  justify-content:space-between;

}
.amountcontainer .amount{
  width:200px;
  height:100px;
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  margin:0px 20px;
}

.purchaseBanner{
  width:100%;
  height:60px;
  background: #187477;
  display:flex;
  justify-content:center;
  align-items:center;
  margin-bottom:20px;
}
.purchaseSpan{
  width: 200px;
  height:30px;
  border: 2px solid #c3996b;
  display:flex;
  justify-content:center;
  align-items:center;
  animation: blink-animation 1.5s infinite;
}
.purchaseBanner strong{
  font-size: 22px;
  color:#c3996b;
}



@keyframes blink-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* hoteldetails container css */

.hotelDetailsContainer{
  display: flex;
  justify-content:space-between;
  align-items:center;
  width:100%;
  margin-bottom: 20px;
}

.hotelDetails{
  display: flex;
  justify-content:start;
  flex-direction:column;
  align-items:start;
  
}

.hotelDetails p{
  margin:0px;
}
/* dropdown menu */



.subcategory-list.open {
  max-height: 1000px; /* Set a maximum height when open */
}

.subcategory-list {
  
  padding: 0px 0px 0px 40px;
  margin: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out; /* Slower transition with ease-in-out timing */
}

.subcategory-list li {
  padding: 5px 0;
  list-style-type: none; /* Remove default bullet points */
  position: relative; /* Required for absolute positioning of pseudo-element */
}

.subcategory-list li::before {
  content: '\2022'; /* Unicode for bullet point */
  color: #c3996b; /* Hide the actual bullet point */
  display: inline-block;
  font-size: 0.8em;
  padding: 0;
  margin-left: -1em; /* Move bullet point to the left to align with text */
  animation: ringGlow 1.5s linear infinite alternate; 
  text-align: center;
  width: 0.9em; /* Adjust size of the ring */
  height: 0.9em; /* Adjust size of the ring */
  border: 1px solid #c3996b; /* Border style for the ring */
  border-radius: 50%;
}


@keyframes ringGlow {
  0% {
    box-shadow: 0 0 0 0 #c3996b; /* Initial glow effect */
  }
  100% {
    box-shadow: 0 0 0 5px rgba(255, 0, 0, 0); /* Final glow effect */
  }
}



/* services contaner css */

.servicesMovingContainer{
  


  width: 100%;
  height: 150px;
  
  
  font-weight: 600;
  display: flex;

  justify-content:center;
  align-items: center;
 position: relative;

}

.servicesMovingContainer img{
  height: 100px;
    width: 100px;
    display: inline;
    margin: 0px 30px; 
}


.cardContainer{
  width: 80%;
  display:flex;
  flex-direction: row;
  justify-content:space-evenly;
  align-items:center;
}

.cardPackage{
  /* height:450px; */
    width:350px;
   
    background-color: #c3996b;
    border-radius: 10px;
    margin:20px;
    padding: 5px;
    position:relative;
    background:rgba(255,255,255,0.7) ;
    box-shadow: 10px 10px 25px rgba(0,0,0,0.2);
    transition: 0.5s;
    display:flex;
    flex-direction: column;
    justify-content:start;
    align-items:start;
  overflow: hidden;
  
}
.cardPackage h1{
  color: black;
  font-size: 22px;
  margin-top:5px;
text-align: left;
  
}
.cardPackage img{
  height: 150px;
  width: 100%;
  border-radius: 5px;
  object-fit: cover;
  width: 100%;
  
}
.buttondiv{
  display: flex;
  justify-content: flex-end;
  align-items: end;
  width:100%;
  height: 100%;
}
.cardPackage button{
  background-color: #c3996b;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  margin-top: 10px;
}
.cardPackage button:hover{
  background-color: #c3996b58;
  color: #c3996b;
}

  @media screen and (max-width: 767px) {
    .commanPageMainContainer{
      width: 100%;
    }
    .commanheader{
      position: relative;
      padding-top: 100px;
      background-color:black;
    }

    .commanPageImageContainer img{
      display: none;
    }
    .amountcontainer{
      flex-direction: column;
    }
    .cardContainer{
      flex-direction: column;
    }
    .otherPackages{
      flex-direction: column;
    }
}


/* other packages css/ */

.otherPackages{
  display:flex;
  justify-content:space-between;
  align-items: center;
  width:100%;
 
  flex-wrap: wrap;

}

.otherPackagesImageConatiner{
  display:flex;
  justify-content:center;
  align-items: center;
  width: 50%;
  flex-direction: column;
  margin: 50px 0px;
  
 
}

.otherPackagesImageConatiner img{
  border-radius: 10px;
  max-width:350px;
  max-height: 350px;
}
.otherPackagesImageConatiner h1{
  color: #c3996b;
  font-size: 32px;
}

.contactusBanner{
  width:100%;
  height:60px;
  background: #187477;
  display:flex;
  justify-content:center;
  align-items:center;
  margin-bottom:20px;
}
.contactusSpan{
  
  height:30px;
 
  display:flex;
  justify-content:center;
  align-items:center;
  animation: blink-animation 1.5s infinite;
}
.contactusBanner strong{
  font-size: 28px;
  color:#c3996b;
}