footer{
  background:#187477;
    color:white;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    position: relative;
    height: auto;
    padding:50px;
    opacity: 0.8;

}
.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: auto;
    flex-grow: 1;
    align-items: flex-start;
}

.footercontainer{
display:flex;
justify-content:space-evenly;
align-items:center;
width:100%;
margin-right: 20px; /* Set the color and style of the line */
  margin-bottom: 20px; 
}

.footercontainer img{

  height: 80px;
  width: 200px;
}
.footercontainer .contactcontainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    
}
.bottom{
   
     /* Set the color and style of the line */
     display:flex;
     align-items:center;
     justify-content:center;
  margin-bottom: 20px;
  width:100%;
}
.contactcontainer h2{
  color:white;
  width: 100%;
}

.contactcontainer a {
    color: white; /* Set the text color */
    text-decoration: none; /* Remove underline */
    font-weight: bold; /* Set font weight */
  }
  
  /* Hover state */
 .contactcontainer  a:hover {
    color: #c3996b; /* Change text color on hover */
    text-decoration: underline; /* Underline on hover */
  }


  .connectdiv{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;

  }
  .connectdiv a{
    margin:5px;
  }
  .connectdiv img{
    width:40px;
    height:40px;
  }
@media screen and (max-width: 767px) {
    
   .container{
    flex-direction: column;
    
    align-items: center;
   }
   .connectdiv{
    justify-content: center;
    flex-wrap: wrap;
   }
}