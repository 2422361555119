header{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.headdiv{
  position: fixed; 
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #01090e; */
  background-color:white;
  top:0;
  /* box-shadow:0px 0px 0px 1px #f54e77; */
  /* box-shadow:0px 0px 0px 1px #c3996b; */
 width: 100%;
 height:90px;
  flex-wrap: wrap;
  transition: background-color 0.3s ease;
  z-index: 400;
  
}

.handburgericon{
  display:none;
}

.logo_container {
 display: flex;
 justify-content: center;
 align-items: center;
 margin-left: 4%;
}
.logo_container a img{

  height: 80px;
  width: 200px;
}

.navbar{
    display: flex;
    align-items: center;
    flex-grow: 0.5;
    justify-content: space-evenly;
    height:inherit
  
}

.navbar a{
  font-size: 14px;
  letter-spacing: .3px;
  color: #187477;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  padding: 31px 0;
  /* border-bottom: 5px solid #01090e; */
  

}
.navbar Link{
  font-size: 14px;
  letter-spacing: .3px;
  color: #187477;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  padding: 31px 0;
  /* border-bottom: 5px solid #01090e; */
  

}
.underline{
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  border-bottom:0px solid white;
}
.underline:hover {
  /* border-bottom: 4px solid #f54e77; */
  border-bottom: 4px solid #c3996b;
}
/* .navbar a:hover {
  border-bottom: 4px solid #f54e77;
  border-bottom: 4px solid black;
} */
/* Media Query for Small Screens (e.g., iPhone size) */
@media screen and (max-width: 767px) {
   .navbar{
    display:none;
    
   }
  .headdiv-active .navbar  {
    flex-direction: column;
    justify-content: center;
    align-items:center; 
    flex-grow: 1;
    display: inline-block;
    /* display:none; */
    /* Stack items vertically on small screens */
  }
  .logo_container {
    margin-left: 0; /* Remove margin for the image on small screens */
   display: flex;
   justify-content: space-around;
   align-items: center;
   width: 100%;
  }
  .handburgericon{
    display: block;
    width: 30px;
    height:60px;
    
    color: #c3996b;
    
  }
  .headdiv{
justify-content:center;

  }

  .headdiv-active{
    position: fixed; 
    display: flex;
    /* justify-content: center; */
    align-items: center;
   flex-direction: column;
    /* background-color: #01090e; */
    background-color:white;
    top:0;
    /* box-shadow:0px 0px 0px 1px #f54e77; */
    /* box-shadow:0px 0px 0px 1px #c3996b; */
   width: 100%;
   /* height:100%; */
    flex-wrap: wrap;
    transition: background-color 0.3s ease;
    z-index: 400;
  }
}


/* moving text css */
.moving-text-container {
  overflow: hidden;
  white-space: nowrap;
  position: fixed;
  z-index: 200;
  top: 90px;
  width: 100%;
  height: 30px;
  color: white;
  background: #187477;
  border-color: 2px solid #c3996b;
  font-weight: 600;
  display: flex;
  align-items: center;
 

}

.moving-text {
  width: 100%;
  animation: moveLeftToRight 12s linear infinite; /* Customize animation duration as needed */
}

@keyframes moveLeftToRight {
  from {
    transform: translateX(-10%);
  }
  to {
    transform: translateX(100%);
  }
}
